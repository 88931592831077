import { useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap-v5'
import { useIntl } from 'react-intl'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../../setup'
import {
  AccountUserWrapperModel,
  UserWrapperModel,
} from '../../modules/auth/models/AccountUserWrapperModel'
import * as users from '../../modules/users/redux/UserRedux'
import * as accounts from './redux/AccountRedux'

type Props = {
  show: any
  setShow: any
}

const AddModeratorModal: React.FC<Props> = ({ show, setShow }) => {
  const dispatch = useDispatch()

  const currentUser = useSelector<RootState>(
    ({ users }) => users.currentUser,
    shallowEqual
  ) as UserWrapperModel
  const verificationResult = useSelector<RootState>(
    ({ users }) => users.verificationResult,
    shallowEqual
  ) as UserWrapperModel[]
  const result = useSelector<RootState>(
    ({ account }) => account.result,
    shallowEqual
  ) as AccountUserWrapperModel[]
  const emailVerification = useSelector<RootState>(
    ({ account }) => account.emailVerification,
    shallowEqual
  ) as AccountUserWrapperModel[]
  const emailIsUsed = useSelector<RootState>(
    ({ account }) => account.emailIsUsed,
    shallowEqual
  ) as any
  const tmpCredentials = useSelector<RootState>(
    ({ account }) => account.tmpCredentials,
    shallowEqual
  ) as any

  const [isLoading, setIsLoading] = useState(false)
  const [emailIsLoading, setEmailIsLoading] = useState(false)
  const [validationLoading, setValidationLoading] = useState(false)
  const [error, setError] = useState(true)
  const [close, setClose] = useState(false)
  const [endpointBody, setEndpointBody] = useState({
    uid: '',
    email: '',
    role: '',
    slackId: '',
    privateFrequency: '',
  })
  const [validationError, setValidationError] = useState({
    uid: false,
    email: false,
  })
  const [blocked, setBlocked] = useState({
    uid: true,
    email: true,
  })
  const intl = useIntl()

  useEffect(() => {
    if (currentUser && currentUser.user.uid === endpointBody.uid && !currentUser.account) {
      setBlocked({ ...blocked, uid: false })
    }
    if (currentUser?.account) {
      setValidationError({ ...validationError, uid: true })
    }
    setIsLoading(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser || verificationResult])

  useEffect(() => {
    if (result) {
      setClose(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [result])

  useEffect(() => {
    setEmailIsLoading(false)
    if (endpointBody.email) setValidationError({ ...validationError, email: true })
    if (endpointBody.email && endpointBody.email === emailIsUsed.email && !emailIsUsed.status) {
      setBlocked({ ...blocked, email: false })
      setValidationError({ ...validationError, email: false })
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [emailVerification])

  function handleClose() {
    setClose(false)
    setValidationError({
      uid: false,
      email: false,
    })
    setIsLoading(false)
    setEmailIsLoading(false)
    setValidationLoading(false)
    setBlocked({
      uid: true,
      email: true,
    })
    setEndpointBody({
      uid: '',
      email: '',
      role: '',
      slackId: '',
      privateFrequency: '',
    })
    setError(true)
    setShow(false)
  }

  function isValidEmail(email: string) {
    return /\S+@\S+\.\S+/.test(email)
  }

  function handleChange(email: string) {
    if (!isValidEmail(email)) {
      setError(true)
    } else {
      setError(false)
    }
  }
  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        backdrop='static'
        keyboard={false}
        scrollable
        size='lg'
      >
        <Modal.Header style={{ marginLeft: 'auto', left: '0', right: '0', marginRight: 'auto' }}>
          {intl.formatMessage({ id: 'MODAL.ADDMODERATOR' })}
        </Modal.Header>
        <Modal.Body>
          <div className='d-flex justify-content-between'>
            <div className='d-flex flex-column'>
              <span className='mt-5'>Uid</span>
              <div className='d-flex'>
                <input
                  className={'form-control form-control-solid w-200px '}
                  value={endpointBody.uid}
                  onChange={(e) => {
                    setBlocked({ ...blocked, uid: true, email: true })
                    setEndpointBody({ ...endpointBody, uid: e.target.value, email: '' })
                  }}
                ></input>
                {isLoading ? (
                  <button className='btn ms-5 btn-light-info'>
                    <span className='spinner-border text-primary'></span>
                  </button>
                ) : (
                  endpointBody.uid && (
                    <button
                      className='btn ms-5 btn-info'
                      onClick={() => {
                        setIsLoading(true)
                        setValidationError({ ...validationError, uid: false })
                        dispatch(users.userActions.get1User(endpointBody.uid))
                      }}
                    >
                      {intl.formatMessage({ id: 'MODAL.CHECK' })}
                    </button>
                  )
                )}
              </div>
              {currentUser === undefined && !validationError.uid && (
                <p className='ms-5' style={{ color: 'red' }}>
                  {intl.formatMessage({ id: 'ERROR.UID.EXIST' })}
                </p>
              )}
              {validationError.uid && (
                <p className='ms-5' style={{ color: 'red' }}>
                  {intl.formatMessage({ id: 'ERROR.UID.MODERATOR' })}
                </p>
              )}
              {!blocked.uid && (
                <>
                  <span className='mt-5'>Email</span>
                  <div className='d-flex'>
                    <input
                      className={'form-control form-control-solid mt2 w-200px '}
                      onChange={(e) => {
                        handleChange(e.target.value)
                        setBlocked({ ...blocked, email: true })
                        setValidationError({ ...validationError, email: false })
                        setEndpointBody({ ...endpointBody, email: e.target.value })
                      }}
                    ></input>
                    {emailIsLoading ? (
                      <button className='btn ms-5 btn-light-info'>
                        <span className='spinner-border text-primary'></span>
                      </button>
                    ) : (
                      !error && (
                        <button
                          className='btn ms-5 btn-info'
                          onClick={() => {
                            setEmailIsLoading(true)
                            dispatch(accounts.accountActions.checkEmailAccount(endpointBody.email))
                            setValidationError({ ...validationError, email: false })
                          }}
                        >
                          {intl.formatMessage({ id: 'MODAL.CHECK' })}
                        </button>
                      )
                    )}
                  </div>
                  {validationError.email && (
                    <p className='ms-5' style={{ color: 'red' }}>
                      {intl.formatMessage({ id: 'ERROR.EMAIL.USED' })}
                    </p>
                  )}
                  {!emailIsUsed.status && endpointBody.email === emailIsUsed.email && (
                    <>
                      <span className='mt-5'> {intl.formatMessage({ id: 'MODAL.ROLE' })}</span>
                      <select
                        name='bounty'
                        data-control='select2'
                        data-hide-search='true'
                        className='form-select form-select-sm w-200px me-10'
                        onChange={(e) => setEndpointBody({ ...endpointBody, role: e.target.value })}
                      >
                        <option value=''>{intl.formatMessage({ id: 'MODAL.SELECTROLE' })}</option>
                        <option value='VIEWER'>{intl.formatMessage({ id: 'MODAL.VIEWER' })}</option>
                        <option value='MODERATOR_NEW'>
                          Moderator New
                        </option>
                        <option value='MODERATOR_CONFIRMED'>
                          Moderator Confirmed
                        </option>
                        <option value='MODERATOR_VETERAN'>
                          Moderator Veteran
                        </option>
                      </select>
                    </>
                  )}
                </>
              )}
              {close && (
                <div className='d-flex flex-column mt-10'>
                  <div>
                    <span>Email : </span>
                    <span className='text-dark fw-bolder fs-6'>{tmpCredentials.email}</span>
                  </div>
                  <div className='mt-4'>
                    <span> {intl.formatMessage({ id: 'MODAL.TMPPASSWORD' })}</span>
                    <span className='text-dark fw-bolder fs-6'>{tmpCredentials.tmpPassword}</span>
                  </div>
                </div>
              )}
            </div>
            {!emailIsUsed.status && endpointBody.email === emailIsUsed.email && (
              <div className='d-flex flex-column me-20 ms-5'>
                <span className='mt-5'>Slack ID ({intl.formatMessage({ id: 'OPTIONAL' })})</span>
                <input
                  className={'form-control form-control-solid w-200px '}
                  onChange={(e) => {
                    handleChange(e.target.value)
                    setBlocked({ ...blocked, email: true })
                    setEndpointBody({ ...endpointBody, slackId: e.target.value })
                  }}
                ></input>
                <span className='mt-5'>
                  {' '}
                  {intl.formatMessage({ id: 'MODAL.PRIVATEFREQUENCY' })} (
                  {intl.formatMessage({ id: 'OPTIONAL' })})
                </span>
                <input
                  className={'form-control form-control-solid w-200px '}
                  onChange={(e) => {
                    handleChange(e.target.value)
                    setBlocked({ ...blocked, email: true })
                    setEndpointBody({ ...endpointBody, privateFrequency: e.target.value })
                  }}
                ></input>
              </div>
            )}
          </div>
        </Modal.Body>
        <Modal.Footer>
          {close ? (
            <button className='btn btn-light-danger' onClick={() => handleClose()}>
              {intl.formatMessage({ id: 'MODAL.CLOSE' })}
            </button>
          ) : (
            <button className='btn btn-light-danger ms-20 me-auto' onClick={() => handleClose()}>
              {intl.formatMessage({ id: 'MODAL.CANCEL' })}
            </button>
          )}
          {!close &&
            endpointBody.role &&
            (validationLoading ? (
              <button className='btn me-20 btn-light-sucess'>
                <span className='spinner-border text-primary'></span>
              </button>
            ) : (
              <button
                className='btn btn-light-success me-20'
                onClick={() => {
                  setValidationLoading(true)
                  dispatch(
                    accounts.accountActions.inviteAccount(
                      endpointBody.uid,
                      endpointBody.email.toLowerCase(),
                      endpointBody.role,
                      endpointBody.slackId,
                      endpointBody.privateFrequency
                    )
                  )
                }}
              >
                {intl.formatMessage({ id: 'MODAL.ADDMODERATOR' })}
              </button>
            ))}
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default AddModeratorModal
